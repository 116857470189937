.card1
    background: $white
    box-shadow: 6px 4px 15px 0px rgba(217, 217, 217, 0.21)
    padding: 34px
    max-width: 406px
    cursor: pointer
    transition: .5s
    margin-bottom: 4rem
    overflow-x: hidden
    a 
        color: inherit
        text-decoration: none
    .icon
        margin-bottom: 2rem
    .title
        padding-bottom:  2rem 
        h3 
            font-size: 20px
            letter-spacing: 0.12px
            margin-bottom: 0

    .text 
        p
            color: $grey
            padding-bottom: 0
            margin-bottom: 2rem

    &:hover
        box-shadow: 6px 4px 15px 0px rgba(73, 189, 242, 0.35)
        transform: scale(1.1)
    .link 
        a 
            color: $primary
            text-decoration: underline

.card2
    background: $white
    box-shadow: 0px 5px 15px 3px rgba(73, 189, 242, 0.35)
    cursor: pointer
    transition: .5s
    max-width: 300px
    text-align: center
    margin: auto
    margin-bottom: 4rem
    &:hover
        box-shadow: 6px 4px 15px 0px rgba(217, 217, 217, 0.21)
        transform: scale(.98)
        
    .img
        position: relative
        img
            width: 100%
        .reduction
            position: absolute
            border-radius: 100%
            top: 0
            right: 0
            width: 84px
            height:  84px
            display: grid
            align-items: center
            text-align: center
            transform: translate(20px, -20px)
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
            fill: radial-gradient(50% 50% at 50% 50%, #F86F03 60.94%, rgba(255, 137, 13, 0.87) 100%)
            background:  radial-gradient(50% 50% at 50% 50%, #F86F03 60.94%, rgba(255, 137, 13, 0.87) 100%)


            h5
                margin-bottom: 0
                color: $white
                font-size: 1.8rem
    .content
        padding: 0 29px 29px 29px
        .entreprise
            background: $primary
            display: inline-block
            padding: 5px 25px
            font-weight: 400
            font-size: 20px
            color: $white
            transform: translateY(-20px)

        .description
            p 
                color: $grey



.card3
    max-width: 500px
    margin: auto
    position: relative
    .img    
        max-width: 381px
        margin: auto
        transform: translateY(6rem)

        img 
            width: 100%
            height: 100%

    .content 
        box-shadow: 0px 5px 15px 3px rgba(73, 189, 242, 0.35)
        padding: 7rem 2rem 2rem 2rem
        background: $white
        .title
            text-align: center
            padding: 2rem 0
            h3
                margin-bottom: 0
        .text 
            ul 
                list-style: none
                padding-left: 0
                li
                    margin-bottom: 1rem
                    display: flex
                .icon 
                    font-size: 1.5rem
                    font-family: "Istok Bold"

        &.bg 
            background: linear-gradient(180deg, rgba(255, 165, 52, 0.88) 0%, #A0400C 100%)
            color: $white
        &.sansBg
            ul
                li
                    color: $primary
                .icon 
                    color: $black
        .mybtn
            bottom: -15px
            left: 0
            right: 0
            position: absolute


.card4
    .card-container
        perspective: 50em
        
        &:nth-child(1)
            --bi: repeating-linear-gradient(30deg, #111 0 0.25em, #333 0 1em)
        
        &:nth-child(2)
            --bi: linear-gradient(#555 5em, #0000 3em), linear-gradient(60deg, #880E4F, #1A237E)

        .card
            position: relative
            width: 320px
            // padding: 3em
            color: #fff
            transform: rotateY(30deg) rotateX(15deg)
            transform-style: preserve-3d
            transition: transform 1s
        
            &:hover
                transform: rotateY(-30deg) rotateX(-15deg)

            p 
                padding: 0 3rem 
            h3  
                background: red
                text-align: center
                padding: 1rem
            background: linear-gradient(180deg, rgba(255, 165, 52, 0.88) 0%, #A0400C 100%)

        .layers
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            transform-style: preserve-3d
            z-index: -1

        .layer
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            border-radius: 1em
            background-image: var(--bi)
            transform: translateZ(var(--tz))
            box-shadow: 0 0 0.5em #000d inset
        
            @for $i from 0 to 10
                &:nth-child(#{$i + 1})
                --tz: #{$i * -4}px

            &:last-child
                box-shadow: 0 0 0.5em #000d inset, 0 0 5px #000

.dashboard
    // background: linear-gradient(180deg, #A0400C 0%, #FFA534 100%)
    background: linear-gradient(180deg, #FFC93B 23.17%, #000 99.97%)


    min-height: 100vh
    .topbar
        background: $white
        padding: .5rem
        .logo
            width: 130px
            img
                width: 100%
                height: 100%
    .sidebar
        background: $white
        padding: 2rem 0
        a 
            text-decoration: none
            color: $black
        ul
            padding-left: 0
            list-style: none
            .active
                li
                    border: 1px solid #fffaeb
                    background: #fffaeb

            li  
                margin: 2rem 0
                padding: .5rem .5rem .5rem 2rem
                transition: .2s ease-in-out
                &:hover
                    background: #fffaeb
                    

    .main
        background: $white
        padding: 2rem 


    .title-section 
        display: flex
        justify-content: space-between
        align-items: center
        h5
            margin-bottom: 0
            

@media  (max-width: 992px)
    .dashboard
        .sidebar
            display: none
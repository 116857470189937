.auth
    overflow-x:  hidden
    .illustration
        min-height: 100%
        background-color:  $white
        background-image: url("../../public/img/hand.png")
        background-size: cover
        background-position: right
        img 
            width: 100%
    .title
        text-align: center
        p
            position: relative
            text-align: center
            &::before
                content: ""
                width: 20%
                height: 1px 
                top: 50%
                bottom:  50%
                left: 0
                position: absolute
                background: rgba(0, 0, 0, 0.10)
            &::after
                content: ""
                width: 20%
                height: 1px 
                top: 50%
                bottom:  50%
                right: 0
                position: absolute
                background: rgba(0, 0, 0, 0.10)
    .login 
        min-height: 100vh
        background: linear-gradient(180deg, #FFC93B 23.17%, #000 99.97%)
        // background: linear-gradient(180deg, rgba(255, 165, 52, 0.88) 0%, #A0400C 100%)
        display: flex
        padding: 2rem
        align-items: center
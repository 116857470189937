.error
    background: #fffaeb
    text-align: center
    padding: 2rem
    border-radius: 5px
    h1 
        font-size: 5rem
        color: #ffc93b

.error404
    background: #fffaeb
    text-align: center
    padding: 2rem
    border-radius: 5px
    height: 100vh
    align-items: center
    display: grid
    h1 
        font-size: 5rem
        color: #ffc93b